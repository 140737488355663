import React, { useState } from 'react';
import styled from 'styled-components';
import { FiArrowLeft } from 'react-icons/fi';
import Swal from 'sweetalert2';

// Estilos del contenedor del paso
const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

// Contenedor de la escala de emojis
const EmojiContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 15px; /* Espacio entre los emojis */

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

// Estilo de cada emoji
const EmojiCircle = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid ${(props) => (props.selected ? '#FF5C37' : '#999')};
  background-color: ${(props) => (props.selected ? '#FF5C37' : 'transparent')};
  color: ${(props) => (props.selected ? '#fff' : '#FF5C37')};
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    font-size: 1.4rem;
  }
`;

// Estilos para el campo de texto del motivo
const ReasonTextArea = styled.textarea`
  width: 100%;
  max-width: 400px;
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  resize: none;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

// Contenedor de botones
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  gap: 10px;
`;

// Estilos del botón "Anterior"
const BackButton = styled.button`
  background-color: #FF5C37;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7043;
  }
`;

// Estilos del botón "Siguiente"
const NextButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #FF5C37;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7043;
  }
`;

const Step5 = ({ onNext, onBack, currentStep, savedScore, savedReason }) => {
  const [selectedScore, setSelectedScore] = useState(savedScore || '');
  const [reason, setReason] = useState(savedReason || ''); // Estado para el motivo
  const [showReason, setShowReason] = useState(false); // Estado para controlar la visibilidad del campo de motivo

  // Emojis para la puntuación
  const emojiOptions = [
    { emoji: '😠', value: 0 },
    { emoji: '🙁', value: 1 },
    { emoji: '😐', value: 2 },
    { emoji: '🙂', value: 3 },
    { emoji: '🥰', value: 4 }
  ];

  // Manejar la selección de puntuación
  const handleScoreChange = (score) => {
    setSelectedScore(score);
    if (score <= 2) {
      setShowReason(true); // Mostrar el campo de motivo si la calificación es baja
    } else {
      setShowReason(false); // Ocultar el campo de motivo si la calificación es alta
      setReason(''); // Limpiar el motivo si la puntuación es mayor a 2
    }
  };

  // Validación y envío
  const handleSubmit = () => {
    // Validación para bloquear el avance sin seleccionar una calificación
    if (selectedScore === '') {
      Swal.fire({
        icon: 'warning',
        title: 'Selecciona una calificación',
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } 
    // Validación para forzar al usuario a ingresar el motivo si la calificación es baja
    else if (selectedScore <= 2 && reason.trim() === '') {
      Swal.fire({
        icon: 'warning',
        title: 'Por favor, dinos el motivo de tu calificación',
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } 
    // Si pasa todas las validaciones, se avanza al siguiente paso
    else {
      onNext(true, { score: selectedScore, reason }); // Pasar la calificación y el motivo
    }
  };

  return (
    <StepContainer>
      <h3>{currentStep}. ¿Cómo calificarías la atención recibida por nuestro personal que te trató?</h3>
      <EmojiContainer>
        {emojiOptions.map((option) => (
          <EmojiCircle
            key={option.value}
            selected={selectedScore === option.value}
            onClick={() => handleScoreChange(option.value)}
          >
            {option.emoji}
          </EmojiCircle>
        ))}
      </EmojiContainer>

      {/* Mostrar campo de motivo solo si la calificación es 0, 1 o 2 */}
      {showReason && (
        <ReasonTextArea
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder="Por favor, dinos el motivo de tu calificación"
        />
      )}

      <ButtonContainer>
        <BackButton onClick={onBack}>
          <FiArrowLeft /> {/* Icono de retroceso */}
        </BackButton>
        <NextButton onClick={handleSubmit}>Siguiente</NextButton>
      </ButtonContainer>
    </StepContainer>
  );
};

export default Step5;