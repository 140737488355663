import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FiArrowLeft } from 'react-icons/fi';
import Swal from 'sweetalert2';

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const OptionItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
`;

const OptionCircle = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid ${(props) => (props.selected ? '#FF5C37' : '#999')};
  background-color: ${(props) => (props.selected ? '#FF5C37' : 'transparent')};
  color: ${(props) => (props.selected ? '#fff' : '#FF5C37')};
  margin-right: 15px;
  font-size: 1.2rem;
  transition: all 0.3s ease;
`;

const OptionText = styled.span`
  text-align: left;
  flex-grow: 1;
`;

const TextInput = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
  font-size: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  gap: 10px;
`;

const BackButton = styled.button`
  background-color: #FF5C37;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7043;
  }
`;

const NextButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #FF5C37;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7043;
  }
`;

const Step8 = ({ onNext, onBack, currentStep, savedSelection = { option: '', otherText: '' } }) => {
  const [selectedOption, setSelectedOption] = useState(savedSelection.option || '');
  const [otherText, setOtherText] = useState(savedSelection.otherText || '');
  const textInputRef = useRef(null);

  // Opciones disponibles
  const options = [
    'Ampliar el horario de atención',
    'Introducir nuevos servicios',
    'Mejorar el tiempo de espera',
    'Ofrecer promociones más frecuentes',
    'Mejorar la atención al cliente',
    'Otro',
  ];

  // Manejar selección de una opción
  const handleOptionChange = (option) => {
    setSelectedOption(option);
    if (option !== 'Otro') {
      setOtherText(''); // Limpiar el campo de texto si no es "Otro"
    }
  };

  useEffect(() => {
    if (selectedOption === 'Otro') {
      textInputRef.current?.scrollIntoView({ behavior: 'smooth' });
      textInputRef.current?.focus();
    }
  }, [selectedOption]);

  const handleSubmit = () => {
    if (!selectedOption) {
      Swal.fire({
        icon: 'warning',
        title: 'Selecciona una opción',
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } else if (selectedOption === 'Otro' && !otherText.trim()) {
      Swal.fire({
        icon: 'warning',
        title: 'Por favor, dinos cómo te gustaría que mejoráramos',
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } else {
      onNext(true, { option: selectedOption, otherText }); // Guardar los valores
    }
  };

  return (
    <StepContainer>
      <h3>{currentStep}. ¿Qué te gustaría que mejoráramos en el salón?</h3>
      {options.map((option, index) => (
        <OptionItem key={index} onClick={() => handleOptionChange(option)}>
          <OptionCircle selected={selectedOption === option}>{index + 1}</OptionCircle>
          <OptionText>{option}</OptionText>
        </OptionItem>
      ))}

      {selectedOption === 'Otro' && (
        <TextInput
          ref={textInputRef}
          value={otherText}
          onChange={(e) => setOtherText(e.target.value)}
          placeholder="Escribe cómo te gustaría que mejoráramos"
        />
      )}

      <ButtonContainer>
        <BackButton onClick={() => onBack({ option: selectedOption, otherText })}>
          <FiArrowLeft /> {/* Icono de retroceso */}
        </BackButton>
        <NextButton onClick={handleSubmit}>Siguiente</NextButton>
      </ButtonContainer>
    </StepContainer>
  );
};

export default Step8;