import React, { useState } from 'react';
import styled from 'styled-components';
import { FiArrowLeft } from 'react-icons/fi';
import Swal from 'sweetalert2';

// Estilos del contenedor del paso
const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

// Contenedor de opciones
const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0px;
  width: 100%;
`;

// Estilo para cada opción
const OptionItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

// Círculo de selección
const OptionCircle = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid ${(props) => (props.selected ? '#FF5C37' : '#999')};
  background-color: ${(props) => (props.selected ? '#FF5C37' : 'transparent')};
  color: ${(props) => (props.selected ? '#fff' : '#FF5C37')};
  margin-right: 15px;
  font-size: 1.2rem;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
    font-size: 1rem;
  }
`;

// Texto de la opción
const OptionText = styled.span`
  flex-grow: 1;
  text-align: left;
`;

// Estilos del campo de texto
const TextArea = styled.textarea`
  width: 100%;
  max-width: 400px;
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  resize: none;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

// Contenedor de botones
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  gap: 10px;
`;

// Estilos del botón "Anterior"
const BackButton = styled.button`
  background-color: #FF5C37;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7043;
  }
`;

// Estilos del botón "Siguiente"
const NextButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #FF5C37;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7043;
  }
`;

const Step7 = ({ onNext, onBack, currentStep, savedScore = '', savedReason = '' }) => {
  const [selectedScore, setSelectedScore] = useState(savedScore);
  const [reason, setReason] = useState(savedReason);
  const [showReason, setShowReason] = useState(savedScore === 'Regular' || savedScore === 'Mala');

  // Opciones de calificación
  const options = ['Excelente', 'Buena', 'Regular', 'Mala'];

  // Manejar selección de opción
  const handleOptionChange = (option) => {
    setSelectedScore(option);
    if (option === 'Regular' || option === 'Mala') {
      setShowReason(true); // Mostrar el campo de texto si la calificación es Regular o Mala
    } else {
      setShowReason(false); // Ocultar el campo de texto si no es Regular o Mala
      setReason(''); // Limpiar el motivo si no es necesario
    }
  };

  // Validación y envío
  const handleSubmit = () => {
    if (!selectedScore) {
      Swal.fire({
        icon: 'warning',
        title: 'Selecciona una opción',
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } else if ((selectedScore === 'Regular' || selectedScore === 'Mala') && reason.trim() === '') {
      Swal.fire({
        icon: 'warning',
        title: 'Por favor, dinos cómo podemos mejorar',
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } else {
      onNext(true, { score: selectedScore, reason }); // Guardar la calificación y el motivo
    }
  };

  return (
    <StepContainer>
      <h3>{currentStep}. ¿Cómo calificarías la relación calidad-precio de nuestros servicios?</h3>
      <OptionContainer>
        {options.map((option, index) => (
          <OptionItem key={index} onClick={() => handleOptionChange(option)}>
            <OptionCircle selected={selectedScore === option}>
              {index + 1}
            </OptionCircle>
            <OptionText>{option}</OptionText>
          </OptionItem>
        ))}
      </OptionContainer>

      {/* Mostrar el campo de texto solo si la opción seleccionada es Regular o Mala */}
      {showReason && (
        <TextArea
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder="Por favor, dinos cómo podemos mejorar"
        />
      )}

      {/* Contenedor con botón "Anterior" y botón "Siguiente" */}
      <ButtonContainer>
        <BackButton onClick={() => onBack({ score: selectedScore, reason })}>
          <FiArrowLeft /> {/* Icono de retroceso */}
        </BackButton>
        <NextButton onClick={handleSubmit}>Siguiente</NextButton>
      </ButtonContainer>
    </StepContainer>
  );
};

export default Step7;