// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MultiStepForm from './components/MultiStepForm';
import ThankYou from './components/ThankYou';

function App() {
  return (
    <Router>
      <Routes>
        {/* Ruta para el formulario */}
        <Route path="/" element={<MultiStepForm />} />

        {/* Ruta para la página de agradecimiento */}
        <Route path="/thank-you" element={<ThankYou />} />
      </Routes>
    </Router>
  );
}

export default App;