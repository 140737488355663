import React, { useState } from 'react';
import styled from 'styled-components';
import { FiArrowLeft } from 'react-icons/fi'; // Icono de flecha para retroceder
import Swal from 'sweetalert2'; // Importamos SweetAlert2

// Estilos del contenedor del paso
const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

// Campo de texto para el distrito
const TextInput = styled.input`
  width: 100%;
  max-width: 300px;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 16px;
  }
`;

// Contenedor de botones
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  gap: 10px;
`;

// Estilos del botón "Anterior"
const BackButton = styled.button`
  background-color: #FF5C37;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7043;
  }

  @media (max-width: 768px) {
    padding: 8px 15px;
    font-size: 0.9rem;
  }
`;

// Estilos del botón "Siguiente"
const NextButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #FF5C37;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7043;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 8px;
  }
`;

const AutocompleteList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 10px 0 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
  background-color: white;
  position: absolute;
  z-index: 1000;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const AutocompleteItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }

  @media (max-width: 768px) {
    padding: 8px;
  }
`;

const Step10 = ({ onNext, onBack, currentStep, savedDistrict }) => {
  const [district, setDistrict] = useState(savedDistrict || '');
  const [filteredDistritos, setFilteredDistritos] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const distritos = [
    "Ancón", "Ate", "Barranco", "Breña", "Carabayllo", "Cercado de Lima", "Chaclacayo", "Chorrillos",
    "Cieneguilla", "Comas", "El agustino", "Independencia", "Jesús maría", "La molina", "La victoria",
    "Lince", "Los olivos", "Lurigancho", "Lurín", "Magdalena del mar", "Miraflores", "Pachacámac",
    "Pucusana", "Pueblo libre", "Puente piedra", "Punta hermosa", "Punta negra", "Rímac", "San bartolo",
    "San borja", "San isidro", "San Juan de Lurigancho", "San Juan de Miraflores", "San Luis", "San Martin de Porres",
    "San Miguel", "Santa Anita", "Santa María del Mar", "Santa Rosa", "Santiago de Surco", "Surquillo",
    "Villa el Salvador", "Villa Maria del Triunfo", "Otro"
  ];

  // Filtrar distritos a medida que el usuario escribe, limitando las sugerencias a 3
  const handleInputChange = (e) => {
    const value = e.target.value;
    setDistrict(value);

    if (value.length > 0) {
      const filtered = distritos
        .filter((distrito) =>
          distrito.toLowerCase().includes(value.toLowerCase())
        )
        .slice(0, 3); // Limitar a un máximo de 3 sugerencias
      setFilteredDistritos(filtered);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  // Seleccionar un distrito de las sugerencias
  const handleSelectDistrict = (distrito) => {
    setDistrict(distrito);
    setShowSuggestions(false); // Ocultar la lista de sugerencias después de seleccionar
  };

  // Validación y envío
  const handleSubmit = () => {
    if (!district.trim()) {
      Swal.fire({
        icon: 'warning',
        title: 'Por favor, selecciona o escribe un distrito',
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } else {
      onNext(true, district); // Pasar el distrito al siguiente paso
    }
  };

  return (
    <StepContainer>
      <h3>{currentStep}. ¿De qué distrito nos visitas?</h3>
      <div style={{ position: 'relative', width: '100%' }}>
        <TextInput
          value={district}
          onChange={handleInputChange}
          placeholder="Escribe tu distrito"
        />
        {showSuggestions && (
          <AutocompleteList>
            {filteredDistritos.map((distrito, index) => (
              <AutocompleteItem
                key={index}
                onClick={() => handleSelectDistrict(distrito)}
              >
                {distrito}
              </AutocompleteItem>
            ))}
          </AutocompleteList>
        )}
      </div>

      {/* Contenedor con botón "Anterior" y botón "Siguiente" */}
      <ButtonContainer>
        <BackButton onClick={() => onBack(district)}>
          <FiArrowLeft /> {/* Icono de retroceso */}
        </BackButton>
        <NextButton onClick={handleSubmit}>Siguiente</NextButton>
      </ButtonContainer>
    </StepContainer>
  );
};

export default Step10;