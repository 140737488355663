import React, { useState } from 'react';
import styled from 'styled-components';
import Swal from 'sweetalert2'; // Importamos SweetAlert2
import { FiArrowLeft } from 'react-icons/fi'; // Icono de flecha para retroceder

// Estilos del contenedor del paso
const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

// Estilos para cada servicio
const ServiceItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    justify-content: flex-start;
  }
`;

// Círculo numerado para el servicio
const ServiceCircle = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid ${(props) => (props.selected ? '#FF5C37' : '#999')};
  background-color: ${(props) => (props.selected ? '#FF5C37' : 'transparent')};
  color: ${(props) => (props.selected ? '#fff' : '#FF5C37')};
  margin-right: 15px;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
    font-size: 1rem;
  }
`;

// Estilos para el texto del servicio
const ServiceText = styled.span`
  text-align: left;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    text-align: left;
  }
`;

// Estilos del botón "Siguiente"
const NextButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #FF5C37;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7043;
  }
`;

// Estilos del botón "Anterior"
const BackButton = styled.button`
  background-color: #FF5C37;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: #ff7043;
  }
`;

// Contenedor de botones
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 0px;
  gap: 10px;
`;

const Step3 = ({ onNext, onBack, savedSelections, currentStep }) => {
  const [selectedServices, setSelectedServices] = useState(savedSelections || []);

  // Lista de servicios disponibles
  const services = [
    "Uñas",
    "Pedicure",
    "Cabellos (tintes, mechas, balayage, baño de color, etc)",
    "Alisados y tratamientos de hidratación o botox",
    "Depilaciones",
    "Masajes",
    "Peinados",
    "Lifting / extensiones de pestañas y planchado de cejas",
    "Maquillaje"
  ];

  // Función para alternar la selección de servicios
  const toggleService = (service) => {
    setSelectedServices((prev) =>
      prev.includes(service)
        ? prev.filter((item) => item !== service)
        : [...prev, service]
    );
  };

  // Función para validar y manejar el envío
  const handleSubmit = () => {
    if (selectedServices.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Selecciona al menos un servicio',
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } else {
      onNext(true, selectedServices); // Si pasa la validación, avanza al siguiente paso y guarda las selecciones
    }
  };

  return (
    <StepContainer>
      <h3>{currentStep}. ¿Qué servicios utilizas con más frecuencia?</h3>
      {services.map((service, index) => (
        <ServiceItem key={index} onClick={() => toggleService(service)}>
          <ServiceCircle selected={selectedServices.includes(service)}>
            {index + 1}
          </ServiceCircle>
          <ServiceText>{service}</ServiceText>
        </ServiceItem>
      ))}

      {/* Contenedor con botones "Anterior" y "Siguiente" */}
      <ButtonContainer>
        <BackButton onClick={onBack}>
          <FiArrowLeft /> {/* Icono de retroceso */}
        </BackButton>
        <NextButton onClick={handleSubmit}>Siguiente</NextButton>
      </ButtonContainer>
    </StepContainer>
  );
};

export default Step3;