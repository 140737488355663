import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FiArrowLeft } from 'react-icons/fi'; // Icono de flecha para retroceder
import Swal from 'sweetalert2'; // Importamos SweetAlert2

// Estilos del contenedor del paso
const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

// Estilos para los campos de entrada
const TextInput = styled.input`
  width: 100%;
  max-width: 300px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
`;

// Contenedor de botones
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  gap: 10px; /* Añadir un espacio entre los botones */
`;

// Estilos del botón "Anterior"
const BackButton = styled.button`
  background-color: #FF5C37;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7043;
  }

  @media (max-width: 768px) {
    padding: 8px 15px;
    font-size: 0.9rem;
  }
`;

// Estilos del botón "Enviar Encuesta"
const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #FF5C37;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: ${({ isLoading }) => (isLoading ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s ease;
  opacity: ${({ isLoading }) => (isLoading ? 0.7 : 1)};

  &:hover {
    background-color: ${({ isLoading }) => (isLoading ? '#FF5C37' : '#ff7043')};
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 8px;
  }
`;

// Estilos para el enlace de Políticas de Privacidad
const PrivacyLink = styled.span`
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  color: #FF5C37;

  &:hover {
    text-decoration: underline;
  }
`;

// Modal y estilos
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  text-align: left;
  max-height: 90vh; /* Limitar la altura del modal */
  overflow-y: auto; /* Habilitar scroll vertical si el contenido excede la altura */
  position: relative;

  @media (max-width: 768px) {
    width: 90%;
    padding: 15px;
  }

  @media (max-width: 480px) {
    width: 95%;
    margin-top: 0px;
    padding: 10px;
  }
`;

const CloseButton = styled.button`
  background-color: #FF5C37;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  float: right;
  margin-top: 10px;

  &:hover {
    background-color: #ff7043;
  }
`;

const FinalStep = ({ onBack, onSubmit }) => {
  const [nombre, setNombre] = useState('');
  const [telefono, setTelefono] = useState('');
  const [dni, setDni] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Nuevo estado para gestionar el cargando
  const formRef = useRef(null); // Añadimos la referencia al formulario

  // Función para abrir el modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Función para cerrar el modal y hacer scroll al formulario
  const closeModal = () => {
    setIsModalOpen(false);
    formRef.current?.scrollIntoView({ behavior: 'smooth' }); // Desplazamiento hacia el formulario
  };

  // Guardar datos en localStorage cuando cambian
  useEffect(() => {
    const savedNombre = localStorage.getItem('nombre');
    const savedTelefono = localStorage.getItem('telefono');
    const savedDni = localStorage.getItem('dni');

    if (savedNombre) setNombre(savedNombre);
    if (savedTelefono) setTelefono(savedTelefono);
    if (savedDni) setDni(savedDni);
  }, []);

  useEffect(() => {
    localStorage.setItem('nombre', nombre);
  }, [nombre]);

  useEffect(() => {
    localStorage.setItem('telefono', telefono);
  }, [telefono]);

  useEffect(() => {
    localStorage.setItem('dni', dni);
  }, [dni]);

  // Validación y envío
  const handleSubmit = () => {
    if (isLoading) return; // Evitar que se envíe mientras está cargando

    let errorMessage = '';

    if (!nombre) {
      errorMessage = 'El campo de nombre es obligatorio.';
    } else if (!telefono) {
      errorMessage = 'El campo de teléfono es obligatorio.';
    } else if (!dni) {
      errorMessage = 'El campo de DNI o Documento es obligatorio.';
    }

    if (errorMessage) {
      Swal.fire({
        icon: 'warning',
        title: errorMessage, // Mensaje detallado del campo que falta
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } else {
      setIsLoading(true); // Activar estado de carga

      // Simulación de envío de datos
      setTimeout(() => {
        onSubmit({ nombre, telefono, dni });
      }, 2000); // Simular un retardo de 2 segundos
    }
  };

  return (
    <StepContainer ref={formRef}> {/* Añadimos la referencia al formulario */}
      <h3>Brindas tus datos para poder participar del sorteo:</h3>

      <TextInput
        type="text"
        value={nombre}
        onChange={(e) => setNombre(e.target.value)}
        placeholder="Nombre completo"
      />
      <TextInput
        type="tel"
        value={telefono}
        onChange={(e) => setTelefono(e.target.value)}
        placeholder="Teléfono"
      />
      <TextInput
        type="number" // Cambiado a tipo número
        value={dni}
        onChange={(e) => {
          const value = e.target.value;
          if (/^\d*$/.test(value)) { // Validar que solo se introduzcan números
            setDni(value);
          }
        }}
        placeholder="DNI o Documento"
      />

      <p>
        Al enviar, aceptas nuestras{' '}
        <PrivacyLink onClick={openModal}>Políticas de Privacidad</PrivacyLink>.
      </p>

      {/* Contenedor con botón "Anterior" y botón "Enviar Encuesta" */}
      <ButtonContainer>
        <BackButton onClick={onBack}>
          <FiArrowLeft /> {/* Icono de retroceso */}
        </BackButton>
        <SubmitButton onClick={handleSubmit} isLoading={isLoading}>
          {isLoading ? 'Enviando...' : 'Enviar Encuesta'}
        </SubmitButton>
      </ButtonContainer>

      {/* Modal para mostrar las políticas de privacidad */}
      {isModalOpen && (
        <ModalBackground>
          <ModalContent>
            <CloseButton onClick={closeModal}>Cerrar</CloseButton>
            <h4>Política de Privacidad y Tratamiento de Datos</h4>
            <p>
              En <strong>Amapola Beauty Salón</strong>, nos comprometemos a
              proteger tu privacidad y garantizar la confidencialidad de tus
              datos personales. Los datos que proporciones a través de nuestro
              formulario serán utilizados exclusivamente para mejorar nuestros
              servicios, ofrecerte sorteos, promociones, descuentos y mejorar tu
              experiencia con nosotros.
            </p>
            <p>
              <strong>Finalidad del tratamiento:</strong> Utilizaremos tus datos
              para:
            </p>
            <ul>
              <li>Mejorar nuestros servicios y adaptarlos a tus necesidades.</li>
              <li>Ofrecerte promociones, sorteos, ofertas y descuentos personalizados.</li>
              <li>Contactarte para evaluar tu experiencia en nuestro salón y sugerir mejoras.</li>
            </ul>
            
            <p>
              <strong>Responsabilidad y protección:</strong> Los datos serán
              tratados exclusivamente por <strong>Amapola Beauty Salón</strong> y nos comprometemos a no compartirlos
              con terceros sin tu consentimiento expreso. Garantizamos que tus
              datos estarán protegidos de acuerdo con las normativas de
              protección de datos aplicables.
            </p>
            <p>
              Tienes derecho a acceder, rectificar o solicitar la eliminación de
              tus datos personales en cualquier momento. Para ejercer estos
              derechos, puedes contactarnos a través de nuestros canales
              oficiales.
            </p>
            <p>Fecha de última actualización: 22 de septiembre de 2024</p>
          </ModalContent>
        </ModalBackground>
      )}
    </StepContainer>
  );
};

export default FinalStep;