import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaFacebookF, FaInstagram, FaTiktok } from 'react-icons/fa'; // Íconos de redes sociales
import Confetti from 'react-confetti'; // Animación de confeti

// Estilos para el contenedor y la imagen del logo
const LogoImage = styled.img`
  width: 250px;
  height: auto;
  margin-bottom: 30px;
`;

const ThankYouContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  align-items: center;
  height: 100vh;
  background-color: #F6ECE7; /* Fondo beige del isotipo de Amapola */
  text-align: center;
`;

const Title = styled.h1`
  font-size: 2rem;
  padding: 4px;
  color: #333;
  margin-bottom: 20px;
`;

const LeadText = styled.p`
  font-size: 1.2rem;
  margin-bottom: 30px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

const SocialButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  padding: 12px;
  font-size: 1.2rem;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
`;

const FacebookButton = styled(SocialButton)`
  background-color: #3b5998;

  &:hover {
    background-color: #344e86;
  }
`;

const InstagramButton = styled(SocialButton)`
  background-color: #e4405f;

  &:hover {
    background-color: #cc3b55;
  }
`;

const TiktokButton = styled(SocialButton)`
  background-color: #000000;

  &:hover {
    background-color: #333333;
  }
`;

const IconWrapper = styled.span`
  display: inline-block;
  margin-right: 10px;
  font-size: 1.5rem;
`;

const ThankYou = () => {
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    // Hacer scroll hacia el inicio de la página cuando se carga el componente
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // Detener el confetti después de 5 segundos
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 5000);

    return () => clearTimeout(timer); // Limpiar el timeout al desmontar el componente
  }, []);

  return (
    <>
      {showConfetti && <Confetti numberOfPieces={500} />}

      <ThankYouContainer>
        {/* Logo de Amapola en la parte superior */}
        <LogoImage src="/logo.webp" alt="Logo Amapola" />

        <Title>¡Gracias por completar la encuesta!</Title>
        <LeadText>Nos encantaría que nos sigas en nuestras redes sociales:</LeadText>

        <ButtonGroup>
          {/* Botón de Facebook */}
          <FacebookButton href="https://www.facebook.com/share/YquVp7xjiZnTz85M/?mibextid=LQQJ4d" target="_blank">
            <IconWrapper><FaFacebookF /></IconWrapper> 
            Síguenos en Facebook
          </FacebookButton>

          {/* Botón de Instagram */}
          <InstagramButton href="https://www.instagram.com/amapolasalon.pe" target="_blank">
            <IconWrapper><FaInstagram /></IconWrapper>
            Síguenos en Instagram
          </InstagramButton>

          {/* Botón de TikTok */}
          <TiktokButton href="https://www.tiktok.com/@amapolasalon.pe" target="_blank">
            <IconWrapper><FaTiktok /></IconWrapper>
            Síguenos en TikTok
          </TiktokButton>
        </ButtonGroup>
      </ThankYouContainer>
    </>
  );
};

export default ThankYou;