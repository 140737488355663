import React from 'react';
import styled, { keyframes } from 'styled-components';

// Animación para el spinner (rotación)
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Estilos para el contenedor del preloader
const PreloaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f6f6f6;
`;

// Estilos para el spinner que será el logo girando
const SpinnerLogo = styled.img`
  width: 100px;
  height: 100px;
  animation: ${spin} 2s linear infinite; /* Hace que gire continuamente */
`;

const Preloader = () => {
  return (
    <PreloaderContainer>
      <SpinnerLogo src="/logo512.png" alt="Logo Amapola girando" />
    </PreloaderContainer>
  );
};

export default Preloader;