import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FiArrowLeft } from 'react-icons/fi'; // Icono de flecha para retroceder
import Swal from 'sweetalert2'; // Importamos SweetAlert2

// Estilos del contenedor del paso
const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

// Estilos para cada opción
const OptionItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

// Círculo para la selección de la opción
const OptionCircle = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid ${(props) => (props.selected ? '#FF5C37' : '#999')};
  background-color: ${(props) => (props.selected ? '#FF5C37' : 'transparent')};
  color: ${(props) => (props.selected ? '#fff' : '#FF5C37')};
  margin-right: 15px;
  font-size: 1.2rem;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
    font-size: 1rem;
  }
`;

// Estilos del texto de la opción
const OptionText = styled.span`
  text-align: left;
  flex-grow: 1;
`;

// Campo de texto para "Otro"
const TextInput = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
  font-size: 1rem;
`;

// Contenedor de botones
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  gap: 10px; /* Añadir un espacio entre los botones */
`;

// Estilos del botón "Anterior"
const BackButton = styled.button`
  background-color: #FF5C37;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7043;
  }
`;

// Estilos del botón "Siguiente"
const NextButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #FF5C37;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7043;
  }
`;

const Step6Music = ({ onNext, onBack, currentStep, savedSelection = {} }) => {
  const [selectedOption, setSelectedOption] = useState(savedSelection.option || '');
  const [otherText, setOtherText] = useState(savedSelection.otherText || ''); // Para la opción "Otro"
  const textInputRef = useRef(null); // Usamos useRef para referenciar el campo de texto

  // Hacer scroll o autofoco cuando seleccionen "Otro"
  useEffect(() => {
    if (selectedOption === 'Otro') {
      textInputRef.current?.scrollIntoView({ behavior: 'smooth' });
      textInputRef.current?.focus(); // Autofoco en el campo de texto
    }
  }, [selectedOption]);

  // Opciones disponibles
  const options = ['Me gustó', 'Normal', 'No me gustó', 'Otro'];

  // Manejar selección de opción
  const handleOptionChange = (option) => {
    setSelectedOption(option);
    if (option !== 'Otro') {
      setOtherText(''); // Si no es "Otro", limpiar el campo de texto
    }
  };

  // Validación y envío
  const handleSubmit = () => {
    if (!selectedOption) {
      Swal.fire({
        icon: 'warning',
        title: 'Selecciona una opción',
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } else if (selectedOption === 'Otro' && !otherText.trim()) {
      Swal.fire({
        icon: 'warning',
        title: 'Escribe tu recomendación',
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    } else {
      onNext(true, { option: selectedOption, otherText }); // Guardar el estado actual y pasar al siguiente paso
    }
  };

  return (
    <StepContainer>
      <h3>{currentStep}. ¿Qué tal te pareció la música de salón?</h3>
      {options.map((option, index) => (
        <OptionItem key={index} onClick={() => handleOptionChange(option)}>
          <OptionCircle selected={selectedOption === option}>{index + 1}</OptionCircle>
          <OptionText>{option}</OptionText>
        </OptionItem>
      ))}

      {/* Campo de texto si seleccionan "Otro" */}
      {selectedOption === 'Otro' && (
        <TextInput
          ref={textInputRef} // Referencia al campo de texto
          value={otherText}
          onChange={(e) => setOtherText(e.target.value)}
          placeholder="Escribe tu recomendación"
        />
      )}

      {/* Contenedor con botón "Anterior" y botón "Siguiente" */}
      <ButtonContainer>
        <BackButton onClick={onBack}>
          <FiArrowLeft /> {/* Icono de retroceso */}
        </BackButton>
        <NextButton onClick={handleSubmit}>Siguiente</NextButton>
      </ButtonContainer>
    </StepContainer>
  );
};

export default Step6Music;